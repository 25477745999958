// noinspection JSUnusedGlobalSymbols,JSUnresolvedVariable

import React from 'react'
import { Layout } from '../components/_layout'
import { Hero } from '../components/_common'
import { Nosotros, Productos, VideoSection, Servicios, Clientes } from '../components/Inicio'
import { Contacto } from '../components/Contacto'
import { graphql } from 'gatsby'


export default function HomePage( { data } ) {
  const productos = data.productosData.edges
  const experiencias = data.experienciasData.edges

  return (
      <Layout withNav seoTitle="Inicio" seoDescription="Empresa 100% mexicana">
        <Hero/>
        <Nosotros/>
        <VideoSection videoURL={ 'https://www.youtube.com/embed/IPL_Tk8vQ6c' }/>
        <Productos { ...{ productos } } />
        <Servicios data={experiencias} />
        <Contacto/>
      </Layout>
  )
}

export const HomepageQuery = graphql`
    query HomepageQuery {

        experienciasData: allContentfulExperiencias{
            edges {
                node {
                    id
                    titulo
                    resumen
                    slug
                    imagenPrincipal {
                        gatsbyImageData(
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                        )
                    }
                }
            }
        }

        serviciosData: allContentfulServicio {
            edges {
                node {
                    id
                    titulo
                    slug
                    slogan
                    imagen {
                        gatsbyImageData(
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                        )
                    }
                }
            }
        }



        productosData: allContentfulProducto(filter: { homepage: { eq: true } }) {
            edges {
                node {
                    id
                    titulo
                    imagen {
                        gatsbyImageData(
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                        )
                    }
                }
            }
        }
    }
`


